<template>
  <div v-if="loaded">
    <div v-for="(question, q_i) of UPData.data" :key="'q_'+q_i">
      <div v-html="splitQuestion(question.title)"></div>

      <v-simple-table dense v-if="question.questionType == 'checkbox'">
        <template v-slot:default>
          <tbody>
            <tr v-for="(answer, a_i) of question.answer" :key="'q_'+q_i+'a_'+a_i">
              <td>{{ answer }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table dense v-else-if="question.questionType == 'titleTimestamp'">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t('title') }}</th>
              <th class="text-left">{{ $t('timestamp') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, a_i) of question.answer" :key="'q_'+q_i+'a_'+a_i">
              <td>{{ row.title }}</td>
              <td>{{ moment(row.timestamp).format('MM/DD/YYYY HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table dense v-if="question.questionType == 'radio'">
        <template v-slot:default>
          <tbody>
            <tr>
              <td>{{ question.answer }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <NoInfo v-if="!(UPData && UPData.data)"/>
  </div>
</template>

<script>
import { DataService } from '../services/DataService'
import NoInfo from "../components/NoInfo.vue"

const dataService = new DataService()

export default {
  components: {
    NoInfo
  },
  data() {
    return {
      loaded: false,
      UPData: {}
    }
  },
  props: {
    userProfilingId: String
  },
  mounted() {
    this.fetchUPData()
  },
  methods: {
    fetchUPData() {
      this.$setLoader()
      dataService.getUserProfileData(this.$store.state.token, { 
        userId: this.$store.state.userProfile._id,
        userProfilingId: this.userProfilingId
      }).then(data => {
        this.loaded = true
        this.$clearLoader()
        this.UPData = data
      })
    },
    splitQuestion(question) {
      let split = question.split("_")

      return `<b>${split[0]}</b><br>${split[1] ? split[1] : ""}`
    }
  }
}
</script>

<style>
.disable-events {
  pointer-events: none
}
</style>