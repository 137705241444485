<template>
  <v-container>
    <v-card class="pa-6">
      <v-card-title>
        {{ name }}
      </v-card-title>
      
      <BrowserHistory v-if="this.dashboardViewDataPath == 'BrowserHistory'" />
      <FacebookUpload v-else-if="this.dashboardViewDataPath == 'FacebookUpload'" />
      <UserProfileData v-else-if="this.dashboardViewDataPath == 'GeneralUPData'"
        :userProfilingId="userProfilingId"
        class="px-4"
      />
    </v-card>
  </v-container>
</template>

<script>
import BrowserHistory from "./browserHistory.vue"
import FacebookUpload from "./facebookUpload.vue"
import UserProfileData from "../../components/UserProfileData.vue"

export default {
  components: {
    BrowserHistory, FacebookUpload, UserProfileData
  },
  data() {
    return {
      dashboardViewDataPath: this.$route.params.dashboardViewDataPath,
      name: this.$route.params.name,
      userProfilingId: this.$route.params.userProfilingId
    }
  },
  mounted() {
    if (!this.userProfilingId) {
      this.$router.push({ name: "Root" })
      this.$clearLoader()
    }
  }
}
</script>

<style>
.disable-events {
  pointer-events: none
}
</style>