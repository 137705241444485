<template>
  <div v-if="$store.state.loader == false" fluid class="pa-0">
    <div v-for="(item, i_index) in topData" :key="'item_'+i_index" class="ma-4">
      <p class="text-h6">{{ $t(item.title) }}</p>

      <v-timeline dense>
        <v-timeline-item v-for="(row, i_index) in item.answer" :key="'row_'+i_index" small>
          <v-card>
            <v-card-text>
              <div class="font-weight-bold">{{ row.title }}</div>
              <div>{{ moment(row.timestamp).format('MM/DD/YYYY HH:mm:ss') }}</div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>

    <NoInfo v-if="topData.length == 0" />
  </div>
</template>

<script>
import { DataService } from "../../services/DataService";
import NoInfo from './../../components/NoInfo'

const dataService = new DataService();

export default {
  components: {
    NoInfo
  },
  data() {
    return {
      topData: [],
    }
  },
  created() {
    this.$store.state.loader = true
  },
  mounted() {
    dataService.passiveDataProcessor(this.$store.state.token, { 
      processorType: 'getUserFacebookUploadData'
    }).then(data => {
      this.topData = data
      this.$store.state.loader = false
    })
  }
}
</script>