<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('title') }}
            </th>
            <th class="text-left">
              {{ $t('url') }}
            </th>
            <th class="text-left">
              {{ $t('visited_at') }}
            </th>
            <th class="text-left">
              {{ $t('actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, item_i) in data.histories" :key="item_i">
            <td style="word-break:break-word">{{ item.title }}</td>
            <td style="word-break:break-word"><a :href="item.url" target="_blank">{{ item.url }}</a></td>
            <td>{{ moment(item.createdAt).format('MM/DD/YYYY HH:mm:ss') }}</td>
            <td>
              <v-btn x-small color="error" @click="deleteItem(item._id)">
                {{ $t('delete') }} 
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-pagination
      v-model="data.page"
      :length="data.totalPages"
      :total-visible="9"
      @input="page => listBrowserHistory(page)"
    ></v-pagination>
  </div>
</template>

<script>
import { DataService } from './../../services/DataService'

const dataService = new DataService()

export default {
  data() {
    return {
      loaded: false,
      data: {
        totalPages: 0,
        page: 1,
        histories: [],
      },
    }
  },
  created() {
  },
  mounted() {
    this.listBrowserHistory()
  },
  methods: { 
    listBrowserHistory(page) {
      if (page) {
        this.data.page = page
      }

      this.$setLoader()

      dataService.listBrowserHistory(this.$store.state.token, {
        userId: this.$store.state.userProfile._id,
        page: this.data.page
      }).then(res => {  
        this.data = res
        this.$clearLoader()
      })
    },
    deleteItem(_id) {
      this.$setLoader()

      dataService.deleteBrowserHistory(this.$store.state.token, {
        userId: this.$store.state.userProfile._id,
        browserHistoryId: _id,
      }).then(() => {
        this.data.histories = this.data.histories.filter(r => r._id != _id)

        if (this.data.histories.length == 0) {
          this.listBrowserHistory(this.data.page)
        } else {
          this.$clearLoader()
        }
      })
    }
  }
}
</script>